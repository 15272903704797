import { BackendService, backendInstance } from "./backend";

export const PROJECT_ID = "menu";

export const EntityName = {
  Category: "category",
  Item: "item",
  Language: "language",
};

export const Source = {
  Header: "header",
  ItemsListing: "items.listing",
  ItemsSearch: "items.seach",
  Cart: "cart",
  ItemModalView: "item.modal.view",
  MerchantInfo: "merchant.info",
};

const parseSubdomainFromUrl = (urlRaw) => {
  if (urlRaw === undefined || urlRaw === null) {
    return null;
  }

  try {
    const url = new URL(urlRaw);
    const subdomain = url.hostname.split(".")[0];
    return subdomain;
  } catch (error) {
    return null;
  }
};

const mapEvent = (e) => {
  return {
    device_timestamp_utc: new Date().toISOString(),
    entity_id: e.entity_id,
    entity_name: e.entity_name,
    event_label: e.event_label,
    merchant_slug: parseSubdomainFromUrl(window.location.href),
    page_url: window.location.href,
    project_id: PROJECT_ID,
    source: e.source,
    custom_fields: e.custom_fields,
  };
};

class AnalyticsCollector {
  constructor(backendService) {
    this.analyticsQueue = [];
    this.ANALYTICS_BATCH_SIZE = 1_000;
    this.ANALYTICS_BATCH_INTERVAL = 5_000;
    this.backendService = backendService;
    this.sendAnalyticsInBackground();
  }

  addToAnalyticsQueue(data) {
    this.analyticsQueue.push(mapEvent(data));
    if (this.analyticsQueue.length >= this.ANALYTICS_BATCH_SIZE) {
      this.sendAnalyticsBatch();
    }
  }

  async sendAnalyticsInBackground() {
    setInterval(() => {
      this.sendAnalyticsBatch();
    }, this.ANALYTICS_BATCH_INTERVAL);
  }

  async sendAnalyticsBatch() {
    if (this.analyticsQueue.length > 0) {
      const batchData = this.analyticsQueue.splice(
        0,
        this.ANALYTICS_BATCH_SIZE
      );
      await this.backendService.sendAnalitycsBatch(batchData);
    }
  }

  async cleanupAndSendRemainingData() {
    await this.sendAnalyticsBatch();
  }
}

export const analyticsInstance = new AnalyticsCollector(backendInstance);
