import { v4 as uuidv4 } from 'uuid';
import { API_URL } from "./config"

class BackendService {
  constructor(headers = {}) {
    this.headers = headers;
    this.merchant_slug = "";
    this.instance = null;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new BackendService();
      const accessToken = localStorage.getItem('accessToken');
      const merchantSlug = localStorage.getItem('merchant_slug');
      if (accessToken && merchantSlug) {
        this.instance.setMerchantSlug(merchantSlug);
      }
    }
    return this.instance;
  }

  async getMerchant() {
    try {
      const responseData = await this.get(`/v1/merchants/${this.merchant_slug}`, 'GET', true)

      if (responseData && !responseData.errors) {
        this.setMerchantSlug(responseData.result.slug);
      }

      return responseData.result
    } catch (error) {
      throw new Error(error)
    }
  }

  async getMerchantAndItems({ limit = 1000, offset = 0 } = {}) {
    let merchantData = null;
    let itemsData = null;
    let merchantSlug = "primerochnaya";

    if (process.env.NODE_ENV === 'production') {
      const domainUrl = window.location.hostname.split('.');
      if (domainUrl.length < 3) {
        throw new Error('Domain is not correct');
      }
      merchantSlug = domainUrl[0];
    }

    try {
      this.setMerchantSlug(merchantSlug);
      merchantData = await this.getMerchant();
    } catch (error) {
      console.error("Error fetching merchant:", error);
    }

    if (merchantData && !merchantData.errors) {
      try {
        const endpoint = `/v1/merchants/${this.merchant_slug}/items?limit=${limit}&offset=${offset}&unvisible=visible`;
        itemsData = await this.get(endpoint);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }

    return {
      merchant: merchantData,
      items: itemsData.results
    };
  }

  async sendAnalitycsBatch(analyticsQueue) {
    const endpoint = `/v1/events`;
    const reqID = this.generateRequestId()

    try {
      await this.post(
        endpoint, { req: analyticsQueue }, 'POST', true, { "batch": "true" }, reqID
      );
      return
    } catch (error) {
      throw new Error(`Send analytics err: (${error?.message}), reqID: (${reqID})`);
    }
  }

  async post(
    path,
    body,
    method = 'POST',
    skipAuthRedirect = false,
    headers = {},
    reqID = this.generateRequestId(),
  ) {
    try {
      const endpoint = `${API_URL}${path}`;

      const isFormData = body instanceof FormData;

      const response = await fetch(endpoint, {
        method,
        body: isFormData ? body : JSON.stringify(body),
        headers: {
          ...headers,
          ...this.headers,
          ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
          'X-Request-ID': reqID,
        },
        credentials: process.env.NODE_ENV === 'production' ? 'include' : 'omit',
      });

      if (!response.ok) {
        const json = await response?.json();
        throw new Error(json?.message);
      }

      try {
        return await response.json();
      } catch (error) {
        return;
      }
    } catch (error) {
      throw new Error(`Do req err, (msg: ${error?.message}) (reqID: ${reqID})`);
    }
  }

  async get(path, method = 'GET', skipAuthRedirect = false) {
    try {
      if (!this.merchant_slug) {
        const localStorageMerchantSlug = localStorage.getItem('merchant_slug')
        this.setMerchantSlug(localStorageMerchantSlug)
      }
      const endpoint = `${API_URL}${path}`;

      const response = await fetch(endpoint, {
        method: method,
        headers: {
          ...this.headers,
          'X-Request-ID': this.generateRequestId(),
        },
        credentials: process.env.NODE_ENV === 'production' ? 'include' : 'omit',
      });

      if (response.status === 401) {
        if (!skipAuthRedirect) {
          this.redirectToAuth();
        }
        throw new Error('Unauthorized');
      }

      if (method === 'DELETE' && response.status === 200) {
        return;
      }

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      throw new Error(error);
    }
  };

  generateRequestId() {
    const newUUID = uuidv4();
    return newUUID;
  }

  setMerchantSlug(slug = 'primerochnaya') {
    this.merchant_slug = slug;
    localStorage.setItem('merchant_slug', slug);
  }
}

export const backendInstance = BackendService.getInstance();
